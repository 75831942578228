import { CONVERSION_FUNNELS } from '../actions/types';


const conversionFunnels = (state = [], action) => {
  switch(action.type) {
    case CONVERSION_FUNNELS:
      return action.payload;
    default:
      return state;
  }
};


export default conversionFunnels;
