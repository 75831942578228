import { combineReducers } from 'redux';
import drawerOpen from './drawerOpen';
import refresh from './refresh';
import trackings from './trackings';
import trackingsFilter from './trackingsFilter';
import trackingsPreDetails from './trackingsPreDetails';
import trackingsLoading from './trackingsLoading';
import trackingsCount from './trackingsCount';
import statusShipmentShipmentIds from './statusShipmentShipmentIds';
import selectedStatusId from './selectedStatusId';
import statusList from './statusList';
import deleteShipment from './deleteShipment';
import pickupModify from './pickupModify';
import taskComment from './taskComment';
import taskCount from './taskCount';
import taskFormInput from './taskFormInput';
import dashboardGetTasks from './dashboardGetTasks';
import countCommentsTasks from './countCommentsTasks';
import crTaskCommentCount from './crTaskCommentCount';
import caSelectedShipment from './caSelectedShipment';
import caGetCustomerById from './caGetCustomerById';
import caGoTo from './caGoTo';
import caLoadAll from './caLoadAll';
import caButtons from './caButtons';
import caInvoicing from './caInvoicing';
import users from './users';
import issuesLoading from './issuesLoading';
import issues from './issues';
import issuesSearchInput from './issuesSearchInput';
import issuesPageRefresh from './issuesPageRefresh';
import issuesCardRefresh from './issuesCardRefresh';
import issuesTransportFilters from './issuesTransportFilters';
import issuesCount from './issuesCount';
import dashboardEventEmailError from './dashboardEventEmailError';
import eventErrorMessage from './dashboardEventErrorMessage';
import dashboardShipments from './dashboardShipments';
import dashboardShipmentsLoading from './dashboardShipmentsLoading';
import dashboardShipmentTransport from './dashboardShipmentTransport';
import shipmentTransportDashLoad from './dashboardShipmentTransportLoad';
import dashboardCalendarLoading from './dashboardCalendarLoading';
import apiKeyAndClientId from './apiKeyAndClientId';
import alertTasksOpen from './alertTasksOpen';
import alertTasks from './alertTasks';
import crFilterButtons from './crFilterButtons';
import crSearch from './crSearch';
import contactRequest from './crContactRequest';
import contactRequestCount from './crContactRequestCount';
import crLoading from './crLoading';
import crDetails from './crDetails';
import crTypes from './crTypes';
import crRefresh from './crRefresh';
import crDate from './crDate';
import statisticsGeneralFilterButtons from './statisticsGeneralFilterButtons';
import statisticsGeneralGroup from './statisticsGeneralGroup';
import statisticsGeneralGroupOlds from './statisticsGeneralGroupOlds';
import statisticsGeneralGroupPareto from './statisticsGeneralGroupPareto';
import statisticsGenCallApi from './statisticsGenCallApi';
import statisticsCustomerFilterButtons from './statisticsCustomerFilterButtons';
import statisticsGet from './statisticsGet';
import statisticsGetPareto from './statisticsGetPareto';
import statisticsN1Get from './statisticsN1Get';
import statisticsN2Get from './statisticsN2Get';
import statisticsN3Get from './statisticsN3Get';
import statisticsLoading from './statisticsLoading';
import statisticsLoadingCount from './statisticsLoadingCount';
import statisticsLoadingPareto from './statisticsLoadingPareto';
import statisticsLoadingParetoCount from './statisticsLoadingParetoCount';
import conversionFunnels from './conversionFunnels';
import downloadFilesLoad from './downloadFilesLoad';
import invoicesMain from './invoicesMain';
import invoicesBilling from './invoicesBilling';
import invoicesLoading from './invoicesLoading';
import invoicesSort from './invoicesSort';
import invoicesDateError from './invoicesDateError';
import invoiceEditResponse from './invoiceEditResponse';
import invoiceCurrent from './invoiceCurrent';
import invoiceOverchargingLoading from './invoiceOverchargingLoading';
import invoicePackageResponse from './invoicePackageResponse';
import invoiceByShipId from './invoiceByShipId';
import invoiceShipIdLoading from './invoiceShipIdLoading';
import invoiceCreateLoading from './invoiceCreateLoading';
import invoiceCreateSuccess from './invoiceCreateSuccess';
import snackbar from './snackbar';
import chatGetShips from './chatGetShips';
import chatLoading from './chatLoading';
import chatError from './chatError';
import chatSearch from './chatSearch';
import chatUnread from './chatUnread';
import chatMarkReadLoad from './chatMarkReadLoad';


export default combineReducers({
  drawerOpen,
  refresh,
  trackings,
  trackingsFilter,
  trackingsPreDetails,
  trackingsLoading,
  trackingsCount,
  statusShipmentShipmentIds,
  selectedStatusId,
  statusList,
  deleteShipment,
  pickupModify,
  taskComment,
  taskCount,
  taskFormInput,
  dashboardGetTasks,
  countCommentsTasks,
  crTaskCommentCount,
  caSelectedShipment,
  caGetCustomerById,
  caGoTo,
  caLoadAll,
  caButtons,
  caInvoicing,
  users,
  issuesLoading,
  issues,
  issuesSearchInput,
  issuesPageRefresh,
  issuesCardRefresh,
  issuesTransportFilters,
  issuesCount,
  dashboardEventEmailError,
  eventErrorMessage,
  dashboardShipments,
  dashboardShipmentsLoading,
  dashboardShipmentTransport,
  shipmentTransportDashLoad,
  dashboardCalendarLoading,
  apiKeyAndClientId,
  alertTasksOpen,
  alertTasks,
  crFilterButtons,
  crSearch,
  contactRequest,
  contactRequestCount,
  crLoading,
  crDetails,
  crTypes,
  crRefresh,
  crDate,
  statisticsGeneralFilterButtons,
  statisticsGeneralGroup,
  statisticsGeneralGroupOlds,
  statisticsGeneralGroupPareto,
  statisticsGenCallApi,
  statisticsCustomerFilterButtons,
  statisticsGet,
  statisticsGetPareto,
  statisticsN1Get,
  statisticsN2Get,
  statisticsN3Get,
  statisticsLoading,
  statisticsLoadingCount,
  statisticsLoadingPareto,
  statisticsLoadingParetoCount,
  conversionFunnels,
  downloadFilesLoad,
  invoicesMain,
  invoicesBilling,
  invoicesLoading,
  invoicesSort,
  invoicesDateError,
  invoiceEditResponse,
  invoiceCurrent,
  invoiceOverchargingLoading,
  invoicePackageResponse,
  invoiceByShipId,
  invoiceShipIdLoading,
  invoiceCreateLoading,
  invoiceCreateSuccess,
  snackbar,
  chatGetShips,
  chatLoading,
  chatError,
  chatSearch,
  chatUnread,
  chatMarkReadLoad,
});
