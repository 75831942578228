import { makeStyles } from "tss-react/mui";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';


const useStyles = makeStyles()(() => {
	return {
		selector: {
			width: '100%',
		},
	}
});

function ConversionFunnels(props) {
	const { classes } = useStyles();
  const { selected,
    setSelected,
    conversionFunnels,
  } = props;

	return (
    <TextField
      className={classes.selector}
      select
      size="small"
      SelectProps={{
        native: true,
      }}
      onChange={(e) => setSelected(Number(e.target.value))}
      value={conversionFunnels.find((option) => option.id === selected) ? conversionFunnels.find((option) => option.id === selected).id : -1}
    >
      <option value={-1} style={{ fontStyle: "italic" }}>
        Not Selected
      </option>
      {conversionFunnels.map(salesPerson => (
          <option value={salesPerson.id} key={salesPerson.id}>
            {salesPerson.name}
          </option>
      ))}
    </TextField>
	);
}

ConversionFunnels.propTypes = {
	selected: PropTypes.number,
	setSelected: PropTypes.func,
}

const mapStateToProps = state => {
  return { conversionFunnels: state.conversionFunnels };
};

export default connect(mapStateToProps)(ConversionFunnels);
