import axios from "axios";
import { URL_All } from "../../../common/Constants";

export async function fetchCustomersData(signal, token, shipment_type, months_period, years_comparison, isActivity, activity, display, conversionfunnels) {
	const response = await axios.post(URL_All.stats + '/stats-customers/', JSON.stringify({
		shipment_type: shipment_type,
		months_period: months_period,
		years_comparison: years_comparison,
		is_activity: isActivity,
		activity: activity,
		display: display,
		conversionfunnels: conversionfunnels,
	}), {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'JWT ' + token
		},
		signal: signal
	});

	let keys = ['new_individuals', 'individuals', 'individuals_parcels', 'individuals_documents', 'individuals_pallets', 'individuals_facturation', 'new_companies', 'companies', 'companies_parcels', 'companies_documents', 'companies_pallets', 'companies_facturation', 'total_customers_month', 'total_ca', 'total_individuals_until', 'total_companies_until', 'total_customers'];
	

	// On ajoute une colonne pour le total des clients
	for (let i = 0; i < response.data.monthsData.length; i++) {
		const month = response.data.monthsData[i];
		const monthContent = month.content;
		monthContent.total_customers = monthContent.total_individuals_until + monthContent.total_companies_until;
	}

	if (isActivity === false) {
		// On trie les mois par mois et par année (ex: Janvier 2021, Janvier 2022, Janvier 2023 puis Février 2021, Février 2022, Février 2023, etc.)
		response.data.monthsData.sort((a, b) => {
			if (a.month !== b.month) {
				return a.month - b.month; // Trier par mois
			} else {
				return b.year - a.year; // Si les mois sont égaux, trier par année
			}
		});
	
	
		// On change a.month qui sont des number en string pour former "Janvier" par exemple
		for (let i = 0; i < response.data.monthsData.length; i++) {
			const month = response.data.monthsData[i];
			month.month = new Date(2021, month.month - 1, 1).toLocaleString('default', { month: 'long' });
		}
	
		// Pour toutes les entrées de monthsData, on ajoute des champs delta au response.data.monthsData.content (qui est un objet) pour chaque entrée (ex: response.data.monthsData.content.ca_total à ca_total_delta, mb_total, mb_total_delta, etc.)
		for (let i = 0; i < response.data.monthsData.length; i++) {
			const month = response.data.monthsData[i];
			const monthContent = month.content;
			for (let j = 0; j < keys.length; j++) {
				const key = keys[j];
				const deltaKey = key + '_delta';
				const previousMonth = response.data.monthsData[i + 1];
				if (previousMonth && previousMonth.month === month.month) {
					// monthContent[deltaKey] = Math.round((monthContent[key] - previousMonth.content[key]) / previousMonth.content[key] * 100 * 100) / 100;
					monthContent[deltaKey] = (monthContent[key] - previousMonth.content[key]) / previousMonth.content[key] * 100;
					if (isNaN(monthContent[deltaKey])) {
						monthContent[deltaKey] = 0;
					}
				} else {
					monthContent[deltaKey] = 0;
				}
			}
		}
	}
	else if (isActivity === true) {
		response.data.monthsData.sort((a, b) => {
			if (a.year !== b.year) {
				return b.year - a.year; // Trier par année décroissante
			} else {
				return b.month - a.month; // Si les années sont égales, trier par mois décroissant
			}
		});

		// On change a.month qui sont des number en string pour former "Janvier" par exemple
		for (let i = 0; i < response.data.monthsData.length; i++) {
			const month = response.data.monthsData[i];
			month.month = new Date(2021, month.month - 1, 1).toLocaleString('default', { month: 'long' });
		}

		// Pour toutes les entrées de monthsData, on ajoute des champs delta au response.data.monthsData.content (qui est un objet) pour chaque entrée (ex: response.data.monthsData.content.ca_total à ca_total_delta, mb_total, mb_total_delta, etc.)
		for (let i = 0; i < response.data.monthsData.length; i++) {
			const month = response.data.monthsData[i];
			const monthContent = month.content;
			for (let j = 0; j < keys.length; j++) {
				const key = keys[j];
				const deltaKey = key + '_delta';
				const previousMonth = response.data.monthsData[i + 1];
				if (previousMonth) {
					monthContent[deltaKey] = (monthContent[key] - previousMonth.content[key]) / previousMonth.content[key] * 100;
					if (isNaN(monthContent[deltaKey])) {
						monthContent[deltaKey] = 0;
					}
				} else {
					monthContent[deltaKey] = 0;
				}
			}
		}
	}

	
	return response;
}