import React from 'react';
import { makeStyles } from 'tss-react/mui';
import ShipmentType from '../fields/ShipmentType';
import MonthsPeriod from '../fields/MonthsPeriod';
import YearsComparison from '../fields/YearsComparison';
import Display from '../fields/Display';
import PropTypes from 'prop-types'
import IsActivity from '../fields/IsActivity';
import Activity from '../fields/Activity';
import ConversionFunnels from '../fields/ConversionFunnels';

const useStyles = makeStyles()((theme) => { return {
	headerSelector: {
		width: '100%',
		display: 'flex',
		alignItems: 'flex-start',
		gap: '6px',
		paddingTop: theme.spacing(2),
	},
	twoRows: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
		width: '100%',
	},
}});

function StatisticsCustomersControls({
	shipmentType,
	monthsPeriod,
	yearsComparison,
	isActivity,
	activity,
	display,
	setShipmentType,
	setMonthsPeriod,
	setYearsComparison,
	setIsActivity,
	setActivity,
	setDisplay,
	conFunnels,
	setConFunnels,
}) {
	const { classes } = useStyles();

	return (
		<div className={classes.headerSelector}>
			<ShipmentType selected={shipmentType} setSelected={setShipmentType} />
			{ isActivity && <Activity selected={activity} setSelected={setActivity} /> }
			{ !isActivity && <MonthsPeriod selected={monthsPeriod} setSelected={setMonthsPeriod} /> }
			{ !isActivity && <YearsComparison selected={yearsComparison} setSelected={setYearsComparison} /> }
			<div className={classes.twoRows}>
				<Display selected={display} setSelected={setDisplay} />
				<ConversionFunnels selected={conFunnels} setSelected={setConFunnels} />
			</div>
			<IsActivity selected={isActivity} setSelected={setIsActivity} />
		</div>
	);
}

StatisticsCustomersControls.propTypes = {
	shipmentType: PropTypes.number,
	monthsPeriod: PropTypes.arrayOf(PropTypes.object),
	yearsComparison: PropTypes.arrayOf(PropTypes.object),
	isActivity: PropTypes.bool,
	activity: PropTypes.number,
	display: PropTypes.number,
	setShipmentType: PropTypes.func,
	setMonthsPeriod: PropTypes.func,
	setYearsComparison: PropTypes.func,
	setIsActivity: PropTypes.func,
	setActivity: PropTypes.func,
	setDisplay: PropTypes.func,
	conFunnels: PropTypes.number,
	setConFunnels: PropTypes.func,
}

export default StatisticsCustomersControls;